<template>

  <!-- {{user}} -->
  <div class="col-lg-9 col-md-9">

    <div class="row">
      <div class="col-lg-12">
        <div class="card card-block card-stretch">
          <div class="card-body">
            <div id="calendar1" class="calendar-s"></div>
            <FullCalendar ref="calendar" :options="calendarOptions" />
          </div>
        </div>
      </div>
    </div>
  </div>
  <modal ref="modalcal" id="modalcal" tabindex="-1" :title="$t('貼文描述')" aria-labelledby="modalcalLabel" aria-hidden="true">
    <model-header>
      <h5 class="modal-title" id="modalcalLabel">{{ $t('加入隊列') }}</h5>
      <button :dismissable="true" type="button" class="btn-close" data-bs-dismiss="modal"></button>
    </model-header>

    <model-body>
      <div class="row">
        <div class="col-md-12">
          <label for="Text5" class="form-label font-weight-bold text-muted text-uppercase">{{ $t('請選擇要執行操作的日期和時間。') }}</label>
        </div>
        <div class="form-group col-md-6">
          <div class="input-group date">
            <div class="input-group date">
              <input v-model="form.selectDate" id="selectDate" name="selectDate" type="date" class="form-control timePicker">
            </div>
          </div>
        </div>
        <div class="form-group col-md-6">
          <div class="input-group date">
            <div class="input-group date">
              <input v-model="form.selectTime" id="selectTime" name="selectTime" type="time" required class="form-control timePicker">
            </div>
          </div>
        </div>

        <div class="col-md-12 mb-3">
          <button @click="goPage('post')" type="button" class="btn btn-outline-primary rounded-pill btn-block">
            <i class="fas fa-solid fa-calendar-plus "></i>
            {{ $t('發貼') }}
          </button>
        </div>
        <!-- <div class="col-md-12 mb-3">
          <button @click="goPage('chat')" type="button" class="btn btn-outline-primary rounded-pill btn-block">
            <i class="lab la-rocketchat fa-lg"></i>
            {{ $t('群發') }}
          </button>
        </div> -->
      </div>
    </model-body>
    <!-- <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
          取消
        </button>
        <button type="      button" class="btn btn-primary">保存</button>
      </div> -->
  </modal>
  <EditPost :isCalendar="true"></EditPost>
</template>
<script>
import '@fullcalendar/core/vdom' // solves problem with Vite
import FullCalendar from '@fullcalendar/vue3'
import interactionPlugin from '@fullcalendar/interaction'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list'
import bootstrapPlugin from '@fullcalendar/bootstrap'
import { getLocalStorageClientToString } from '@/utils/assist'
// import moment from 'moment'
import { mapGetters } from 'vuex'
import zhTw from '@fullcalendar/core/locales/zh-tw'
import { getQueue } from '@/api/queue'
console.log(zhTw)
import EditPost from '@/views/Apps/Social/Components/EditPost'

//中文說明 https://www.helloweba.net/javascript/452.html
// https://www.helloweba.net/javascript/445.html
export default {
  name: 'Calendar',
  components: {
    EditPost,
    FullCalendar // make the <FullCalendar> tag available
  },
  computed: {
    ...mapGetters({
      user: 'User/userState',
      token: 'User/tokenState',
      isPlan: 'User/isPlanState',
      planDate: 'User/planDateState'
    })
  },
  data() {
    return {
      viewModeOptions: [
        {
          text: 'Monthly',
          value: 'month'
        },
        {
          text: 'Weekly',
          value: 'week'
        },
        {
          text: 'Daily',
          value: 'day'
        }
      ],
      calendarOptions: {
        plugins: [
          dayGridPlugin,
          timeGridPlugin,
          interactionPlugin, // needed for dateClick
          listPlugin,
          bootstrapPlugin
        ],
        locale:
          getLocalStorageClientToString('locale') === null ||
          getLocalStorageClientToString('locale') === 'zh-TW'
            ? zhTw
            : '', // 英文給空值
        timeZone: 'local',
        headerToolbar: {
          left: 'prev,next today',
          center: 'title',
          // right: 'dayGridMonth,timeGridWeek,timeGridDay,listYear'
          right: 'dayGridMonth,listYear'
        },

        weekNumbers: false,
        initialView: window.innerWidth < 765 ? 'listYear' : 'dayGridMonth', // 手機版本顯示清單, pc版顯示日曆
        initialEvents: [], // alternatively, use the `events` setting to fetch from a feed
        editable: false, //啟動拖曳調整日期
        selectable: true, // 是否可選擇
        selectMirror: true,
        dayMaxEvents: true,
        weekends: true, // 顯示星期六跟星期日
        dateClick: this.handleDateClick,
        eventClick: this.handleEventClick,
        eventsSet: this.handleEvents,
        // contentHeight: window.innerWidth < 765 ? 'auto' : '600px',
        contentHeight: window.innerHeight - window.innerHeight / 4,
        handleWindowResize: true,
        /*
         * color = 貼文 #4731b6 , 大量訊息 #f4a965
         */
        events: [
          // {
          //   // 事件
          //   title: '約會約會約會約會約會約會約會約會約會',
          //   type: '貼文',
          //   start: '2022-07-29 03:30:00',
          //   color: '#4731b6',
          //   url: ''
          // },
          // {
          //   // 事件(包含開始時間)
          //   title: '中餐中餐中餐中餐中餐中餐中餐中餐中餐中餐',
          //   type: '大量訊息',
          //   start: '2022-07-30 03:30:00',
          //   color: '#f4a965',
          //   url: ''
          // }
        ]
      },
      form: {
        selectDate: '2022-07-27',
        selectTime: '16:26'
      },
      rows: []
    }
  },
  created() {
    this.getRows()
    // this.$toast.show(`Hey! I'm here`)
    // this.$toast.success(`Hey! I'm here`)
    // this.$toast.error(`Hey! I'm here`)
    // this.$toast.warning(`Hey! I'm here`)
    // this.$toast.info(`Hey! I'm here`)
  },
  methods: {
    goPage(type) {
      if (
        this.$moment(
          `${this.form.selectDate} ${this.form.selectTime}`
        ).isBefore(this.$moment().format('YYYY-MM-DD HH:mm'))
      ) {
        this.$toast.show(this.$t('請選擇更晚的時間'))

        return false
      }
      switch (type) {
        case 'post':
          this.$refs.modalcal.hide()
          this.$store.dispatch('User/isPlanAction', true)
          this.$store.dispatch(
            'User/addPlanDateAction',
            `${this.form.selectDate} ${this.form.selectTime}`
          )

          setTimeout(() => {
            this.$router.push({ name: 'social.list' })
          }, 200)
          break
        case 'chat':
          this.$refs.modalcal.hide()
          this.$store.dispatch('User/isPlanAction', true)
          setTimeout(() => {
            this.$router.push({ name: 'app.chat' })
          }, 200)
          break
      }
    },
    getRows() {
      getQueue()
        .then(res => {
          this.calendarOptions.events = res.data.map(item => {
            return {
              title: `[${item.type_name}] ${item.content.substr(0, 5)}...`,
              type: item.type_name,
              start: item.date_time,
              postId: item.post_id,
              color: item.post === 'post' ? '#4731b6' : '#f4a965',
              url: ''
            }
          })
        })
        .catch(err => {
          console.log(err)
          this.$swal.mixin().fire({
            icon: 'error',
            text: err.response.data.message
          })
        })
    },
    handleDateClick(selectInfo) {
      const selectDate = selectInfo.dateStr
      const currentDate = this.$moment().format('YYYY-MM-DD')
      if (selectDate < currentDate) {
        this.$toast.show(this.$t('不可選擇過去的日期'))
        return false
      }
      if (selectDate == currentDate) {
        /* 如果是今天，將時間定位到當前整點 */
        this.form.selectTime = this.$moment()
          .add(1, 'hours')
          .format('HH:00')
      } else {
        this.form.selectTime = '00:00'
      }
      this.form.selectDate = selectInfo.dateStr
      this.$refs.modalcal.show()
    },
    handleEventClick(clickInfo) {
      // 手機版彈出 dialog 這邊處理或者點擊以後連結到編輯貼文
      console.log('handleEventClick', clickInfo.event._def.extendedProps)
      this.$store.dispatch('User/dialogEditPostAction', true)
      this.$store.dispatch(
        'User/dialogEditPostIdAction',
        clickInfo.event._def.extendedProps.postId
      )
    },
    handleEvents(events) {
      console.log('handleEvents', events)
      this.currentEvents = events
    }
  }
}
</script>

<style>
.fc-theme-standard td, .fc-theme-standard th {
  border: none;
}
.fc .fc-list-event-time {
  min-width: 82px;
}
</style>
